<template>
  <v-app>
    <v-app-bar
      v-if="
        drawNavigationFrames &&
        currentPage !== undefined &&
        currentPage !== loginPageIndex &&
        currentPage !== autoLoginIndex
      "
      app
      dense
      flat
      light
    >
      <v-breadcrumbs v-if="crumbs.length > 1" v-bind:items="crumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>

      <v-spacer />

      <v-chip
        v-if="newVersionAvailable"
        v-on:click="$router.go()"
        color="error"
        class="px-4 me-8"
      >
        <v-icon left small>mdi-alert</v-icon>
        <span>{{ $t("newVersionAvailable") }}</span>
      </v-chip>

      <v-chip
        v-if="buildedSessionCount > 0"
        v-on:click="$router.push({ name: 'VisualAnalysis' })"
        color="primary"
        class="px-4 me-4"
      >
        <v-icon left small>mdi-chart-sankey</v-icon>
        {{ $t("assetsInSession") }}:
        <strong class="ms-2">{{ buildedSessionCount }}</strong>
      </v-chip>

      <v-chip
        v-if="alarmsCount > 0"
        v-on:click="
          currentPage != pages.ALARMS_PAGE
            ? $router.push({ name: 'Alarms' })
            : () => {}
        "
        color="error"
        class="px-4 me-4"
      >
        <v-icon left small>mdi-alarm-light-outline</v-icon>
        {{ alarmsCount }}
      </v-chip>

      <v-btn
        color="primary"
        v-on:click="rightDrawerCars = !rightDrawerCars"
        icon
      >
        <v-icon>mdi-cogs</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" icon>
            <v-icon>{{ userIcon }}</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item class="mb-6">
            <v-list-item-avatar color="primary">
              <v-icon dark>{{ userIcon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="title">
              {{ currentUser.fullName() }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-bind:disabled="currentPage == pages.SETTINGS_PAGE"
            v-on:click="$router.push({ path: '/settings' })"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ $t("btnEditUser") }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-on:click="logout()" link>
            <v-list-item-icon>
              <v-icon color="error">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="error--text">
              {{ $t("btnLogout") }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="
        drawNavigationFrames &&
        currentPage !== undefined &&
        currentPage !== loginPageIndex &&
        currentPage !== autoLoginIndex
      "
      v-bind:style="'background-color: ' + appColor"
      id="main-drawer"
      app
      dark
      expand-on-hover
      floating
      permanent
    >
      <v-list-item class="px-2 pb-3">
        <v-list-item-avatar color="white">
          <v-img v-bind:src="appLogo" />
        </v-list-item-avatar>
        <v-list-item-title class="title">
          {{
            currentCompany == undefined || currentCompany.name == undefined
              ? appName
              : currentCompany.name
          }}
        </v-list-item-title>
      </v-list-item>

      <v-list dense nav>
        <v-list-item-group v-model="currentPage">
          <div v-for="page in sortedPages()" v-bind:key="page.name">
            <v-list-item
              v-if="page.position < firstBottomIndex"
              v-show="page.minRole >= currentUser.role && showMenu(page)"
              v-bind:disabled="
                currentPage === page.position || page.minRole < currentUser.role
              "
              v-on:click="$router.push({ path: page.path })"
              link
            >
              <v-list-item-icon>
                <v-badge
                  v-if="displayAlarmsBadge(page.position)"
                  v-bind:content="alarmsCount < 10 ? alarmsCount : '10+'"
                  color="error"
                  overlap
                >
                  <v-icon>{{ page.icon }}</v-icon>
                </v-badge>
                <v-badge
                  v-else-if="displayAnalysisBadge(page.position)"
                  content="!"
                  color="error"
                  overlap
                >
                  <v-icon>{{ page.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>{{ page.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="body-1">
                {{ $t(page.name) }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>

      <v-list dense nav>
        <v-list-item-group v-model="currentCustomPage">
          <v-list-item
            v-for="(customMenu, index) in customMenus"
            v-bind:key="customMenu.name + '-' + customMenu.url"
            v-bind:disabled="currentCustomPage === index"
            v-on:click="customMenuClicked(index, customMenu)"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ customMenu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="body-1">
              {{ customMenu.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <v-list dense nav>
          <v-list-item-group v-model="currentPageBottom">
            <div v-for="page in sortedPages()" v-bind:key="page.name">
              <v-list-item
                v-if="page.position >= firstBottomIndex"
                v-show="page.minRole >= currentUser.role && showMenu(page)"
                v-bind:disabled="
                  currentPage === page.position ||
                  page.minRole < currentUser.role
                "
                v-on:click="$router.push({ path: page.path })"
                link
              >
                <v-list-item-icon>
                  <v-icon>{{ page.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="body-1">
                  {{ $t(page.name) }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>

        <v-list dense>
          <v-list-item class="mt-3" disabled>
            <v-list-item-icon>
              <v-icon
                v-bind:class="{ spinning: connectionItem.animated }"
                v-bind:color="connectionItem.color"
              >
                {{ connectionItem.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="body-1">
              {{ $t(connectionItem.text) }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="px-2" disabled>
            <template v-if="vendorName && vendorLogo">
              <v-list-item-avatar>
                <v-img v-bind:src="vendorLogo" />
              </v-list-item-avatar>
              <v-list-item-title class="d-flex flex-column ms-2">
                <div class="body-2">{{ vendorName }}</div>
                <div class="caption">{{ currentVersion }}</div>
              </v-list-item-title>
            </template>
            <template v-else>
              <v-list-item-avatar class="d-flex flex-column" height="1.2em">
                <div class="caption">{{ currentVersion }}</div>
              </v-list-item-avatar>
            </template>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-if="
        drawNavigationFrames &&
        currentPage !== undefined &&
        currentPage !== loginPageIndex &&
        currentPage !== autoLoginIndex
      "
      v-model="rightDrawerCars"
      width="32vw"
      absolute
      floating
      right
      temporary
    >
      <v-card-title>
        {{ $t("allAssets") }}: {{ filteredAssets.length }}
      </v-card-title>
      <v-text-field
        v-model="assetSearchTerm"
        prepend-inner-icon="mdi-magnify"
        class="mx-4 mb-4"
        clearable
        hide-details
        dense
        outlined
        v-bind:placeholder="$t('searchCarsPlaceholder') + '...'"
      />
      <v-list class="px-2">
        <div v-for="asset in filteredAssets" v-bind:key="asset.id">
          <v-list-item v-on:click="toAssetDetails(asset.id)" link>
            <v-list-item-content>
              <h3>{{ asset.plate || asset.boxMacAddress }}</h3>
              <div>{{ asset.brand }} {{ asset.model }}</div>
            </v-list-item-content>
            <v-list-item-action-text>
              <h3 class="mb-1">
                <Dot
                  v-bind:color="
                    isOffline[asset.boxMacAddress] ||
                    isOffline[asset.boxMacAddress] == undefined
                      ? 'error'
                      : 'success'
                  "
                  v-bind:text="
                    $t(
                      isOffline[asset.boxMacAddress] ||
                        isOffline[asset.boxMacAddress] == undefined
                        ? 'offline'
                        : 'online'
                    )
                  "
                  justify-end
                />
              </h3>
              <div>{{ asset.serial }}</div>
            </v-list-item-action-text>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <!-- Feedback snackbars -->

    <v-snackbar v-model="presentError" color="error" top>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="pa-0" dense>
            <v-list-item-content class="body-1 font-weight-bold">
              {{ error.description }}
            </v-list-item-content>
          </v-list-item>
        </template>

        <span>
          {{ parseErrorMessage(error.error) }}
        </span>
      </v-tooltip>

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" v-on:click="presentError = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="presentSuccess" color="success" top>
      <v-list-item class="pa-0" dense>
        <v-list-item-content class="body-1 font-weight-bold">
          {{ success }}
        </v-list-item-content>
      </v-list-item>

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" v-on:click="presentSuccess = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="fetching" width="unset" persistent>
      <v-card class="pa-4">
        <v-card-title class="mx-0 px-0 mt-0 pt-0">
          <v-spacer />
          <v-progress-circular color="primary" indeterminate />
          <v-spacer />
        </v-card-title>
        <v-card-text class="mx-0 px-0 mb-0 pb-0">
          <v-spacer />
          {{ $t("fetchingData") }}
          <v-spacer />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style lang="scss">
h1 {
  margin-bottom: 0.5em;
}

.spinning {
  animation: spin 0.8s ease infinite;
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: white !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c1d69;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #0c1d69;
  border-radius: 4px;
}

::selection {
  background: #0c1d69;
  color: white;
}
</style>

<style scoped>
.clipped-bottom {
  position: absolute;
  bottom: 0;
}

#main-drawer:hover {
  box-shadow: 0 0 1em 1px #424242;
}
</style>

<script>
import ApiRequests from "./utils/requests";
import Connection from "./utils/connection";
import Messages from "./utils/messages";
import Pages from "./utils/pages";
import Dot from "./components/Dot";
import moment from "moment";
import Favico from "favico.js";

export default {
  name: "App",

  components: { Dot },

  data: () => ({
    presentError: false,
    itemsPerPage: 20,
    error: {},
    success: false,
    presentSuccess: undefined,
    assetSearchTerm: undefined,
    connectionState: Connection.DISCONNECTED,
    rightDrawerCars: false,
    firstBottomIndex: Pages.FIRST_BOTTOM,
    loginPageIndex: Pages.LOGIN_PAGE,
    autoLoginIndex: Pages.AUTOMATIC_LOGIN_PAGE,
    pages: Pages,
    newVersionAvailable: false,
    versionInterval: undefined,
    faviconBadge: new Favico({
      animation: "popFade",
      position: "up",
    }),
  }),

  async beforeMount() {
    // Change the app page title
    document.title = this.browserName;

    this.$bus.$on(Messages.ERROR_MSG, this.presentErrorMessage);
    this.$bus.$on(Messages.SUCCESS_MSG, this.presentSuccessMessage);
    this.$signalr.$on(Messages.SIGNALR_CONNECTED_MSG, this.connectedToServer);
    this.$signalr.$on(
      Messages.SIGNALR_DISCONNECTED_MSG,
      this.disconnectedFromServer
    );
    this.$signalr.$on(
      Messages.SIGNALR_RECONNECTING_MSG,
      this.reconnectingWithServer
    );

    this.$signalr.$on(this.signalRMessages, this.manageMessage);

    this.checkVersion();
    this.versionInterval = setInterval(
      () => this.checkVersion(),
      5 * 60 * 1000 // 5 minutes
    );
  },

  mounted() {
    if (!this.loggedIn || this.currentUser === undefined) {
      // Get current user again
      ApiRequests.userCurrent(
        (res) => {
          this.$store.commit("setCurrentUser", res.data);
          this.$store.commit("setLogged", true);

          if (res.data.companyId != undefined) {
            ApiRequests.getCurrentCustomer(
              (res) => this.$store.commit("setCurrentCompany", res.data),
              (err) =>
                process.env.NODE_ENV === "development"
                  ? console.error(err)
                  : undefined
            );
          }
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.logout(!this.loggedIn);
        }
      );

      // Get session token again
      ApiRequests.tokenCurrent(
        (res) => {
          const token = res.data || {};

          this.$store.commit("setCurrentToken", token);
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    }
  },

  beforeDestroy() {
    if (this.versionInterval !== undefined) {
      clearInterval(this.versionInterval);
      this.versionInterval = undefined;
    }

    this.$bus.$off(Messages.ERROR_MSG, this.presentErrorMessage);
    this.$bus.$off(Messages.SUCCESS_MSG, this.presentSuccessMessage);
    this.$signalr.$off(Messages.SIGNALR_CONNECTED_MSG, this.connectedToServer);
    this.$signalr.$off(
      Messages.SIGNALR_DISCONNECTED_MSG,
      this.disconnectedFromServer
    );
    this.$signalr.$off(
      Messages.SIGNALR_RECONNECTING_MSG,
      this.reconnectingWithServer
    );

    this.$signalr.$off(this.signalRMessages, this.manageMessage);
    this.$store.commit("clearOfflineInterval");
    this.$store.commit("clearSessionRefresh");
    this.disconnectedFromServer();
  },

  computed: {
    drawNavigationFrames() {
      return this.$store.getters.drawNavigationFrames;
    },
    currentVersion() {
      return this.$store.getters.currentVersion;
    },
    hiddenMenus() {
      return this.$store.getters
        .array("VUE_APP_APP_HIDDEN_MENUS")
        .filter((p) => p != undefined && p != "");
    },
    customMenus() {
      return this.$store.getters.object("VUE_APP_APP_CUSTOM_MENUS");
    },
    eventsHeaders() {
      return [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventStatus"), align: "start", sortable: false, value: "graphics", width: "12%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventName"), align: "start", sortable: false, value: "message" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventTimeStamp"), align: "start", sortable: false, value: "ts" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventAsset"), align: "start", sortable: false, value: "originId" },
      ];
    },
    connectionItem() {
      return Connection.STATES[this.connectionState];
    },
    signalRMessages() {
      return this.$store.getters.string("VUE_APP_HUB_MSGS_MessageReceive");
    },
    filteredAssets() {
      return this.assetsList
        .filter((v) => !!v.boxMacAddress)
        .filter((v) => v.matchSearchTerm(this.assetSearchTerm))
        .sort((v1, v2) => {
          const lastSeen1 = this.lastSeenBySerial(v1.serial) ?? moment(0);
          const lastSeen2 = this.lastSeenBySerial(v2.serial) ?? moment(0);

          return lastSeen1.isBefore(lastSeen2) ? 1 : -1;
        });
    },
    alarmsCount() {
      return this.$store.getters.alarmsCount;
    },
    crumbs() {
      return this.$store.getters.pages;
    },
    currentPageBottom: {
      get() {
        return this.$store.getters.currentPage - Pages.FIRST_BOTTOM;
      },
      set() {
        /* Do nothing */
      },
    },
    buildedSessionCount() {
      return this.$store.getters.buildedSessionCount;
    },
  },

  watch: {
    loggedIn(newValue, oldValue) {
      if (!(newValue && oldValue) && newValue) {
        this.fetchAllData();
      }
    },
    alarmsCount(alarmsNumber) {
      this.faviconBadge.badge(alarmsNumber);
    },
  },

  methods: {
    connectedToServer(id) {
      this.$store.commit("setClientId", id);
      this.connectionState = Connection.CONNECTED;
    },
    disconnectedFromServer() {
      ApiRequests.deleteFilters(
        this.clientId,
        () => {
          this.$store.commit("setClientId", undefined);
          this.connectionState = Connection.DISCONNECTED;
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    reconnectingWithServer() {
      this.connectionState = Connection.RECONNECTING;
    },
    manageMessage(msg) {
      if (msg.message != undefined) {
        const rtMessage =
          typeof msg.message === "string"
            ? JSON.parse(msg.message)
            : msg.message;

        rtMessage.isFromSignalR = true;

        this.$store.dispatch("saveMessage", rtMessage);
      }
    },
    sortedPages() {
      return Pages.menuItems().sort((p1, p2) =>
        p1.position > p2.position ? 1 : -1
      );
    },
    showMenu(menuEntry) {
      return menuEntry.required || !this.hiddenMenus.includes(menuEntry.path);
    },
    displayAlarmsBadge(item) {
      return item === Pages.ALARMS_PAGE && this.alarmsCount > 0;
    },
    displayAnalysisBadge(item) {
      return item === Pages.COMPARISONS_PAGE && this.buildedSessionCount > 0;
    },
    parseErrorMessage(msg) {
      return msg?.toString()?.replace("Error: ", "");
    },
    presentErrorMessage(msg) {
      this.error = msg;
      this.presentError = true;
    },
    presentSuccessMessage(msg) {
      this.success = msg;
      this.presentSuccess = true;
    },
    toAssetDetails(id) {
      this.$router.push({ path: `/asset-details/${id}` });
      this.rightDrawerCars = false;
    },
    customMenuClicked(index, customMenu) {
      if (customMenu.external) {
        window.open(customMenu.url, "_blank").focus();

        return;
      }

      this.$router.push({
        path: "/custom-page",
        query: { page: index, url: customMenu.url },
      });
    },
    checkVersion() {
      ApiRequests.checkVersion(
        this.currentVersion,
        () => {},
        () => (this.newVersionAvailable = true)
      );
    },
  },
};
</script>
