<template>
  <v-dialog v-model="show" width="min(45%, 92vw)" persistent scrollable>
    <v-card>
      <v-card-title style="display: flex; align-items: center">
        <span>{{ $t("btnCommands") }}</span>
        <v-spacer />
        <v-btn
          v-bind:title="$t('btnExpandCommands')"
          v-on:click="$router.push({ path: `/asset-commands/${assetId}` })"
          color="primary"
          text
          fab
          small
        >
          <v-icon>mdi-arrow-expand</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <CommandsList v-bind:commands="enrichedCommands" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-on:click="closeDialog" color="error" text>
          {{ $t("btnClose") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BoxCommand from "../models/BoxCommand";
import CommandsList from "./CommandsList";

export default {
  name: "AssetCommandsDialog",

  components: { CommandsList },

  props: {
    assetId: { type: String, required: true },
    commands: { type: Array, required: true },
  },

  data: () => ({
    show: true,
    typesList: Object.keys(BoxCommand.types),
  }),

  computed: {
    enrichedCommands() {
      return this.commands.map((cmd) => {
        const boxCommand = new BoxCommand();

        boxCommand.name = cmd.name;
        boxCommand.description = cmd.description;
        boxCommand.assetIds = [this.assetId];
        boxCommand.arguments = {
          wrap: cmd.arguments?.wrap == "true",
          args: JSON.parse(cmd.arguments?.args || "[]"),
        };

        boxCommand.type = this.getCommandType(boxCommand);

        return boxCommand;
      });
    },
  },

  methods: {
    getCommandType(cmd) {
      if (cmd.arguments.args.length === 0) return "";

      if (cmd.arguments.wrap) return "JSON";

      switch (cmd.arguments.args[0].type) {
        case "NUMBER":
          return "NUMERIC";
        case "BOOL":
          return "BOOL";
        case "STRING ARRAY":
          return "JSON";
        case "NUMBER ARRAY":
          return "JSON";
        default:
          return "TEXT";
      }
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>
