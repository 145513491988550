import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Settings from "../views/Settings.vue";
import Alarms from "../views/Alarms.vue";
import AssetDetails from "../views/AssetDetails.vue";
import Assets from "../views/Assets.vue";
import Groups from "../views/Groups.vue";
import Customers from "../views/Customers.vue";
import Users from "../views/Users.vue";
import VisualAnalysis from "../views/VisualAnalysis.vue";
import Analysis from "../views/Analysis.vue";
import Boxes from "../views/Boxes.vue";
import Rules from "../views/Rules.vue";
import AssetMoreDetails from "../views/AssetMoreDetails.vue";
import AssetDynamicDetails from "../views/AssetDynamicDetails.vue";
import CustomerDetails from "../views/CustomerDetails.vue";
import CustomPage from "../views/CustomPage.vue";
import ApiRequests from "../utils/requests";
import AutomaticLogin from "../views/AutomaticLogin.vue";
import AssetCommands from "../views/AssetCommands.vue";
import CommandsSettings from "../views/CommandsSettings.vue";
import PluginsSettings from "../views/PluginsSettings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/asset-commands/:assetId",
    name: "AssetCommands",
    component: AssetCommands,
    meta: { requiresLogin: true },
  },
  {
    path: "/plugins_settings",
    name: "PluginsSettings",
    component: PluginsSettings,
    meta: { requiresLogin: true },
  },
  {
    path: "/commands_settings",
    name: "CommandsSettings",
    component: CommandsSettings,
    meta: { requiresLogin: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresLogin: true },
  },
  {
    path: "/alarms",
    name: "Alarms",
    component: Alarms,
    meta: { requiresLogin: true },
  },
  {
    path: "/assets",
    name: "Assets",
    component: Assets,
    meta: { requiresLogin: true },
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
    meta: { requiresLogin: true },
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    meta: { requiresLogin: true },
  },
  {
    path: "/customers-details/:customerId",
    name: "CustomerDetails",
    component: CustomerDetails,
    meta: { requiresLogin: true },
  },
  {
    path: "/groups-details/:groupId",
    name: "GroupDetails",
    component: CustomerDetails,
    meta: { requiresLogin: true },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { requiresLogin: true },
  },
  {
    path: "/visual-analysis",
    name: "VisualAnalysis",
    component: VisualAnalysis,
    meta: { requiresLogin: true },
  },
  {
    path: "/analysis",
    name: "Analysis",
    component: Analysis,
    meta: { requiresLogin: true },
  },
  {
    path: "/boxes",
    name: "Boxes",
    component: Boxes,
    meta: { requiresLogin: true },
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
    meta: { requiresLogin: true },
  },
  {
    path: "/asset-details/:assetId",
    name: "AssetDetails",
    component: AssetDetails,
    meta: { requiresLogin: true },
  },
  {
    path: "/asset-more-details/:assetId",
    name: "AssetMoreDetails",
    component: AssetMoreDetails,
    meta: { requiresLogin: true },
  },
  {
    path: "/asset-dynamic-details/:assetId",
    name: "AssetDynamicDetails",
    component: AssetDynamicDetails,
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresLogin: true },
  },
  {
    path: "/custom-page",
    name: "CustomPage",
    component: CustomPage,
    meta: { requiresLogin: true },
  },
  {
    path: "/automatic-login",
    name: "AutomaticLogin",
    component: AutomaticLogin,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "*",
    name: "Default",
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, _from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresLogin) &&
    store.getters.currentUser === undefined
  ) {
    ApiRequests.userCurrent(
      (res) => {
        store.commit("setCurrentUser", res.data);
        store.commit("setLogged", true);
        next();
      },
      () => router.replace({ path: "/login" })
    );
  } else {
    next();
  }
});

export default router;
