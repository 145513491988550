<template>
  <v-container
    class="fill-height"
    style="display: flex; align-items: center; justify-content: center"
    fluid
  >
    <div
      v-if="!loading"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <v-btn color="primary" v-on:click="login" fab text>
        <v-icon>mdi-sync</v-icon>
      </v-btn>
      <v-btn color="primary" v-on:click="login" text>
        {{ $t("retryLogin") }}
      </v-btn>
    </div>
    <div v-else>
      <v-progress-circular color="primary" indeterminate />
    </div>
  </v-container>
</template>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import axios from "axios";

export default {
  name: "AutomaticLogin",

  data: () => ({
    loading: false,
    redirect: undefined,
    token: undefined,
    hideNavigation: false,
  }),

  beforeMount() {
    this.$store.commit("setLogged", false);
    this.$store.commit("setCurrentUser", undefined);
    this.$store.commit("setCurrentToken", {});
    this.$store.commit("setCurrentCompany", undefined);
    this.$store.commit("setGroups", []);
    this.$store.commit("setUsers", []);
    this.$store.commit("setCustomers", []);
    this.$store.commit("enumerateAssets", []);
    this.$store.commit("setCurrentPage", Pages.AUTOMATIC_LOGIN_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);

    // Get params
    this.hideNavigation =
      this.$route.query?.hideNavigation?.toLowerCase() === "true";
    this.redirect = decodeURI(this.$route.query?.redirect || "");
    this.token = this.$route.query?.token || "";
  },

  mounted() {
    // this.$store.commit("addPage", {
    //   text: this.$t("menuAutomaticLogin"),
    //   to: "/automatic-login",
    //   root: true,
    // });
    this.$store.commit("addPage", {
      text: this.$t("menuHome"),
      to: "/dashboard",
      root: true,
    });

    this.$store.commit("setDrawNavigationFrames", !this.hideNavigation);
    this.checkAlreadyLogged(this.login);
  },

  methods: {
    parseToken(token) {
      return {
        email: token,
        password: token,
      };
    },
    checkAlreadyLogged(errorCallback = () => {}) {
      // Get logged user
      ApiRequests.userCurrent(
        (res) => {
          this.loading = false;
          this.$store.commit("setCurrentUser", res.data);
          this.$store.commit("setLogged", true);

          if (res.data.companyId != undefined) {
            ApiRequests.getCurrentCustomer(
              (res) => {
                this.$store.commit("setCurrentCompany", res.data);
                this.$router.replace({ path: this.redirect });
              },
              (err) => {
                process.env.NODE_ENV === "development"
                  ? console.error(err)
                  : undefined;

                errorCallback(err);
              }
            );
          } else {
            this.$router.replace({ path: this.redirect });
          }
        },
        (err) => {
          errorCallback(err);
        }
      );

      // Get session token
      ApiRequests.tokenCurrent(
        (res) => {
          const token = res.data || {};
          this.$store.commit("setCurrentToken", token);
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    async login() {
      this.error = undefined;
      this.loading = true;

      let credentials = {
        email: undefined,
        password: undefined,
      };

      try {
        // Get login credentials
        credentials = (
          await axios.post("/loginproxy/get-login", {
            cacheId: this.token,
          })
        ).data;
      } catch (err) {
        if (process.env.NODE_ENV === "development") console.error(err);
        this.loading = false;
        this.$bus.$emit(Messages.ERROR_MSG, {
          error: err?.response?.data?.error || err,
          description: this.$t("loginFailure"),
        });
        return;
      }

      // Login and redirect
      ApiRequests.userLogin(
        credentials,
        () => {
          this.checkAlreadyLogged((err) => {
            this.loading = false;
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("loginFailure"),
            });
          });
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loading = false;
          const errStatus = err.response?.status;
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description:
              errStatus === 404 || errStatus === 400
                ? this.$t("invalidCredentials")
                : this.$t("loginFailure"),
          });
        }
      );
    },
  },
};
</script>
