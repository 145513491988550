import UserInfo from "../models/UserInfo";

/**
 * @typedef {Object} MenuItem
 * @property {String} name - Name of the app drawer item.
 * @property {String} icon - Icon of the app drawer item.
 * @property {String} path - Navigation destination.
 * @property {Number} position - Position in the app drawer.
 * @property {Number} minRole - Minimum role for visualization.
 */

class Pages {
  /* Constants */
  static AUTOMATIC_LOGIN_PAGE = -10;
  static ASSET_COMMANDS_PAGE = -9;
  static PLUGINS_SETTINGS_PAGE = -8;
  static COMMANDS_SETTINGS_PAGE = -7;
  static DYNAMIC_PAGE = -6;
  static CUSTOMER_DETAILS_PAGE = -5;
  static MORE_DETAILS_PAGE = -4;
  static DETAILS_PAGE = -3;
  static LOGIN_PAGE = -2;
  static TEMPLATE_PAGE = -1;
  static HOME_PAGE = 0;
  static ASSETS_PAGE = 1;
  static GROUPS_PAGE = 2;
  static BOXES_PAGE = 3;
  static ALARMS_PAGE = 4;
  static RULES_PAGE = 5;
  static COMPARISONS_PAGE = 6;
  static ANALYSIS_PAGE = 7;

  static FIRST_BOTTOM = 8;

  static CUSTOMERS_PAGE = 8;
  static USERS_PAGE = 9;
  static SETTINGS_PAGE = 10;

  /**
   * Get the navigation menu elements.
   * @public
   * @static
   * @returns {MenuItem} - List of elements to be displayed in the app drawer.
   */
  static menuItems() {
    return [
      {
        name: "menuHome",
        icon: "mdi-view-grid-outline",
        path: "/dashboard",
        position: Pages.HOME_PAGE,
        minRole: UserInfo.roles.OPERATOR,
        required: false,
      },
      {
        name: "menuAssets",
        icon: "mdi-cogs",
        path: "/assets",
        position: Pages.ASSETS_PAGE,
        minRole: UserInfo.roles.OPERATOR,
        required: true,
      },
      {
        name: "menuAlarms",
        icon: "mdi-car-brake-alert",
        path: "/alarms",
        position: Pages.ALARMS_PAGE,
        minRole: UserInfo.roles.OPERATOR,
        required: true,
      },
      {
        name: "menuRules",
        icon: "mdi-greater-than-or-equal",
        path: "/rules",
        position: Pages.RULES_PAGE,
        minRole: UserInfo.roles.OPERATOR,
        required: false,
      },
      {
        name: "menuGroups",
        icon: "mdi-folder-table-outline",
        path: "/groups",
        position: Pages.GROUPS_PAGE,
        minRole: UserInfo.roles.ADMIN,
        required: false,
      },
      {
        name: "menuVisualAnalysis",
        icon: "mdi-chart-sankey",
        path: "/visual-analysis",
        position: Pages.COMPARISONS_PAGE,
        minRole: UserInfo.roles.OPERATOR,
        required: false,
      },
      // {
      //   name: "menuAnalysis",
      //   icon: "mdi-chart-bar-stacked",
      //   path: "/analysis",
      //   position: Pages.ANALYSIS_PAGE,
      //   minRole: UserInfo.roles.OPERATOR,
      //   required: false,
      // },
      {
        name: "menuBoxes",
        icon: "mdi-cube-outline",
        path: "/boxes",
        position: Pages.BOXES_PAGE,
        minRole: UserInfo.roles.OPERATOR,
        required: false,
      },
      {
        name: "menuCustomers",
        icon: "mdi-domain",
        path: "/customers",
        position: Pages.CUSTOMERS_PAGE,
        minRole: UserInfo.roles.SUPER_ADMIN,
        required: false,
      },
      {
        name: "menuUsers",
        icon: "mdi-account-group-outline",
        path: "/users",
        position: Pages.USERS_PAGE,
        minRole: UserInfo.roles.MANAGER,
        required: true,
      },
      {
        name: "menuSettings",
        icon: "mdi-cog-outline",
        path: "/settings",
        position: Pages.SETTINGS_PAGE,
        minRole: UserInfo.roles.OPERATOR,
        required: true,
      },
    ];
  }
}

export default Pages;
