<template>
  <div class="pa-4 fill-height grid-container">
    <h1>{{ pageTitle }}</h1>

    <div class="body">
      <CommandsList v-bind:commands="enrichedCommands" class="body-content" />
    </div>
  </div>
</template>

<style>
.grid-container {
  display: grid;
  gap: 0.1rem;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
}

.body {
  position: relative;
}

.body-content {
  position: absolute;
  inset: 0;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Messages from "../utils/messages";
import Pages from "../utils/pages";
import AssetInfo from "../models/AssetInfo";
import BoxCommand from "../models/BoxCommand";
import CommandsList from "../components/CommandsList";

export default {
  name: "AssetCommands",

  components: { CommandsList },

  data: () => ({
    assetId: undefined,
    asset: new AssetInfo(),
  }),

  computed: {
    assetDbId() {
      return this.$route.params.assetId;
    },
    pageTitle() {
      return this.$t("menuAssetCommands").replace(
        "%asset%",
        this.asset?.plate ||
          this.asset?.boxMacAddress ||
          this.asset?.serial ||
          this.$t("assetNoPlate")
      );
    },
    commandsList() {
      return this.$store.getters.allCommands;
    },
    assetCommands() {
      return this.commandsList.filter((cmd) => {
        const applyToAllAssets =
          (cmd.assetIds || []).length === 0 &&
          (cmd.assetEntityCodes || []).length === 0;
        const userEnabled =
          (cmd.userIds || []).length === 0 ||
          cmd.userIds.includes(this.currentUser.id);
        const assetEnabled = (cmd.assetIds || []).includes(this.asset.id);
        const ecEnabled = (cmd.assetEntityCodes || []).includes(
          this.asset.entityCode
        );

        return (
          cmd.enabled &&
          userEnabled &&
          (applyToAllAssets || assetEnabled || ecEnabled)
        );
      });
    },
    enrichedCommands() {
      return this.assetCommands.map((cmd) => {
        const boxCommand = new BoxCommand();

        boxCommand.name = cmd.name;
        boxCommand.description = cmd.description;
        boxCommand.assetIds = [this.assetId];
        boxCommand.arguments = {
          wrap: cmd.arguments?.wrap == "true",
          args: JSON.parse(cmd.arguments?.args || "[]"),
        };

        boxCommand.type = this.getCommandType(boxCommand);

        return boxCommand;
      });
    },
  },

  async beforeMount() {
    try {
      await this.fetchAssetData();
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.error(err);
      this.$router.replace({ path: "/dashboard" });
      this.$bus.$emit(Messages.ERROR_MSG, {
        error: err?.response?.data?.error || err,
        description: this.$t("assetFetchFailure").replace(
          "%serial%",
          this.assetId
        ),
      });
      return;
    }

    this.updateFiltersRules(this.clientId);

    this.$store.commit("setCurrentPage", Pages.ASSET_COMMANDS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("btnCommands"),
      to: `/asset-commands/${this.assetDbId}`,
      root: false,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    async fetchAssetData() {
      this.asset = new AssetInfo(
        (await ApiRequests.getAsset(this.assetDbId, undefined, undefined)).data
      );

      this.assetId = this.asset.serial;
      this.asset.companyName = this.customersList.find(
        (c) => c.id === this.asset.companyId
      )?.name;
      this.asset.groupName = this.groupsList.find(
        (f) => f.id === this.asset.groupId
      )?.name;
    },
    getCommandType(cmd) {
      if (cmd.arguments.args.length === 0) return "";

      if (cmd.arguments.wrap) return "JSON";

      switch (cmd.arguments.args[0].type) {
        case "NUMBER":
          return "NUMERIC";
        case "BOOL":
          return "BOOL";
        case "STRING ARRAY":
          return "JSON";
        case "NUMBER ARRAY":
          return "JSON";
        default:
          return "TEXT";
      }
    },
  },
};
</script>
